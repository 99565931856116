const ioClient = require('socket.io-client');
const ss = require('socket.io-stream');

const Api = {}

Api.getManager = (server, opts) => {
    //console.log("getManager:",server,opts);
    return new ioClient.Manager(server,opts);
}
Api.getSS = ()=> ss
Api.getSocketStream = (socket)=> ss(socket)

module.exports=Api;