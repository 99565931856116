import { Typography, Box, Grid, Stack, Skeleton } from "@mui/material";

export default function DeviceDetailsSkeleton({ deviceId }) {
	return (
		<Box sx={{}}>
			<Skeleton
				height={40}
				width={400}
				animation="wave"
				sx={{ marginLeft: "auto", marginRight: "auto" }}
			/>
			<Grid
				container
				direction="row"
				sx={{
					gridAutoColumns: "1fr",
					gap: 1,
					display: "grid",
				}}
			>
				<Grid
					item
					container
					direction="row"
					gridRow={1}
					justifyContent="center"
				>
					<Typography>Loading data for device {deviceId}...</Typography>
				</Grid>
				<Grid
					item
					container
					direction="row"
					gridRow={2}
					sx={{ marginTop: 5, marginBottom: 5 }}
				>
					<Grid
						container
						item
						xs
						alignItems="flex-start"
						sx={{ marginLeft: 2, marginRight: 2, marginTop: 5 }}
					/>
					<Grid container item xs={6}>
						<Skeleton
							animation="wave"
							height={350}
							width="90%"
							variant="rectangular"
						/>
					</Grid>
					<Grid
						container
						item
						xs
						sx={{ marginTop: 5, marginLeft: 2, marginRight: 2 }}
					>
						<Stack spacing={1} width="100%">
							<Skeleton animation="wave" height={50} width="100%" />
							<Skeleton animation="wave" height={50} width="100%" />
							<Skeleton animation="wave" height={50} width="100%" />
						</Stack>
					</Grid>
				</Grid>

				<Grid
					item
					container
					direction="row"
					gridRow={3}
					sx={{ marginTop: 0, marginBottom: 5 }}
				>
					<Grid xs container item sx={{ marginLeft: 2 }}>
						<Stack sx={{ width: "100%" }} spacing={1}>
							<Skeleton animation="wave" height={50} width="80%" />
							<Skeleton animation="wave" height={50} width="80%" />
						</Stack>
					</Grid>
					<Grid item container xs={6}>
						<Stack
							sx={{ width: "100%", marginLeft: 1, marginRight: 1 }}
							spacing={1}
						>
							<Skeleton animation="wave" height={50} width="80%" />
							<Skeleton animation="wave" height={50} width="80%" />
						</Stack>
					</Grid>
					<Grid item container xs sx={{ marginRight: 2, marginLeft: 2 }}>
						<Stack sx={{ width: "100%", marginLeft: 2 }} spacing={1}>
							<Skeleton animation="wave" height={50} width="80%" />
						</Stack>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
