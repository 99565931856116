import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Paper,
	TableContainer,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { useState } from "react";

export default function AddNoteDialog({ open, onClose, onSave }) {
	const [note, setNote] = useState("");
	const [saveLocation, setSaveLocation] = useState(true);

	const handleSaveLocationChange = (e) => {
		setSaveLocation(e.target.value);
	};

	return (
		<Dialog
			open={open}
			keepMounted
			fullWidth={true}
			maxWidth="md"
			onClose={onClose}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle>Add note to device</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					<TableContainer component={Paper} sx={{ marginTop: 1 }}>
						<TextField
							fullWidth
							multiline
							rows={4}
							value={note}
							onChange={(e) => {
								setNote(e.target.value);
							}}
						/>
					</TableContainer>
				</DialogContentText>
				<DialogContentText sx={{ paddingTop: 5 }}>
					<FormControl fullWidth>
						<InputLabel id="savelocation-select-label">
							Save location
						</InputLabel>
						<Select
							labelId="savelocation-select-label"
							id="autopenown-select"
							value={saveLocation}
							label="Save location"
							onChange={handleSaveLocationChange}
						>
							<MenuItem value={false}>Local</MenuItem>
							<MenuItem value={true}>Network</MenuItem>
						</Select>
					</FormControl>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
				<Button
					onClick={() => {
						onSave(note, saveLocation);
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
