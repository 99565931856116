import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Search from "./Search.jsx";
import { useNavigate } from "react-router-dom";

function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const [allOpen, setAllOpen] = React.useState(false);
	const { allId } = props;
	const [storage, setStorage] = React.useState();

	const navigate = useNavigate();

	function showId() {
		setOpen(!open);
	}
	function addOwn() {
		const name = row.name;
		let ownPremises = localStorage.getItem("ownPremises");
		if (ownPremises) {
			ownPremises = JSON.parse(ownPremises);
		} else {
			ownPremises = [];
		}
		if (!ownPremises.find((e) => e.name === name)) {
			const newEntry = { name };
			ownPremises.push(newEntry);
			localStorage.setItem("ownPremises", JSON.stringify(ownPremises));
		} else {
			console.log("premise is already on list");
		}
		setStorage(ownPremises);
	}
	function dellOwn() {
		const name = row.name;
		row.name = "";
		row.address = "";
		row.country = "";
		row.amount = "";

		let ownHouse = localStorage.getItem("ownPremises");
		let item;
		if (ownHouse) {
			ownHouse = JSON.parse(ownHouse);
			item = ownHouse.find((e) => {
				e.name === name;
			});
			ownHouse.splice(ownHouse.indexOf(item), 1);
			localStorage.setItem("ownPremises", JSON.stringify(ownHouse));
		}
		setStorage(ownHouse);
	}

	function Select({ allId }) {
		const openid = allId;

		if (openid) {
			return (
				<Box sx={{ margin: 1 }}>
					<Table size="small" aria-label="purchases">
						<TableBody>
							{row.devices?.map((device) => (
								<TableRow key={device}>
									<TableCell
										component="th"
										scope="row"
										sx={{
											backgroundColor: "#f0f0f0",
										}}
									>
										<Button onClick={handleCellClick}>{device}</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			);
		}
		return (
			<Collapse in={open} timeout="auto" unmountOnExit>
				<Box sx={{ margin: 1 }}>
					<Table size="small" aria-label="purchases">
						<TableBody>
							{row.devices?.map((device) => (
								<TableRow key={device}>
									<TableCell
										component="th"
										scope="row"
										sx={{
											backgroundColor: "#f0f0f0",
										}}
									>
										<Button onClick={handleCellClick}>{device}</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</Collapse>
		);
	}

	const handleCellClick = (e) => {
		navigate(`/device/${e.target.textContent}`);
	};

	return (
		<>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell component="th" scope="row">
					<Button onClick={showId}>{row.name}</Button>
				</TableCell>
				<TableCell align="right">{row.address}</TableCell>
				<TableCell align="right">{row.country}</TableCell>
				<TableCell align="right">{row.amount}</TableCell>
				<TableCell align="right">
					<Button
						aria-label="expand row"
						sx={{ margin: 1 }}
						size="small"
						onClick={addOwn}
						variant="contained"
					>
						ADD
					</Button>
					<Button
						aria-label="expand row"
						size="small"
						onClick={dellOwn}
						variant="contained"
					>
						del
					</Button>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Select allId={allId} />
				</TableCell>
			</TableRow>
		</>
	);
}

export default function CollapsibleTable() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [rowData, setRowData] = React.useState([]);
	const [allId, setAllId] = React.useState(false);
	React.useEffect(() => {
		let usettings = localStorage.getItem("usersettings");
		if (usettings) {
			usettings = JSON.parse(usettings);
			if (usettings.autoopensearchresults) {
				setAllId(true);
			}
		}
	}, []);

	function getPremises(a) {
		setRowData(a.data);
	}

	function showAll() {
		setAllId(!allId);
	}
	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e) => {
		setRowsPerPage(+e.target.value);
		setPage(0);
	};

	return (
		<div>
			<Paper sx={{ width: "100%" }} variant="elevation">
				<Typography
					variant="h5"
					color="white"
					sx={{ backgroundColor: "#2CB4DF" }}
				>
					<Search getPremises={getPremises} />
				</Typography>

				<TableContainer>
					<Table aria-label="collapsible table">
						<TableHead sx={{ backgroundColor: "#f0f0f0" }}>
							<TableRow>
								<TableCell>
									<Button onClick={showAll} variant="outlined">
										PREMISE NAME
									</Button>
								</TableCell>
								<TableCell align="right">ADDRESS</TableCell>
								<TableCell align="right">COUNTRY</TableCell>
								<TableCell align="right">DEVICES</TableCell>
								<TableCell align="right" />
							</TableRow>
						</TableHead>
						<TableBody>
							{rowData.length ? (
								rowData
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => <Row key={row.name} row={row} allId={allId} />)
							) : (
								<TableRow>
									<TableCell colSpan={4}>No results</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			<TablePagination
				component="div"
				rowsPerPageOptions={[5, 10, 50, 100]}
				count={rowData.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				sx={{ mb: 2, backgroundColor: "#2CB4DF" }}
			/>
		</div>
	);
}
