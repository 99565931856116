import React, { useState, useEffect, useRef } from "react";
import {
	Grid,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TextField,
} from "@mui/material";

export default function DeviceDetailsVariables({ connection }) {
	const [variables, setVariables] = useState([
		{ name: "digitIntegerStats", value: {} },
		{ name: "digitDecimalStats", value: {} },
		{ name: "rawDigitsDecimalValue", value: -1 },
		{ name: "rawDigitsIntegerValue", value: -1 },
		{ name: "rawPointerValue", value: -1 },
		{ name: "digitsIntegerValue", value: -1 },
		{ name: "digitsDecimalValue", value: -1 },
		{ name: "pointerValue", value: -1 },
		{ name: "consumption", value: -1 },
		{ name: "lastSentTimeStamps", value: -1 },
		{ name: "digitsFail", value: -1 },
		{ name: "rssi", value: -1 },
	]);

	useEffect(() => {
		async function subscibe() {
			if (connection) {
				connection.on("variable", deviceVariableChanged);
				console.log("subscribing to variables");
				const digitIntegerStats =
					await connection.getVariable("digitIntegerStats");
				deviceVariableChanged({
					name: "digitIntegerStats",
					value: digitIntegerStats,
				});
				const digitDecimalStats =
					await connection.getVariable("digitDecimalStats");
				deviceVariableChanged({
					name: "digitDecimalStats",
					value: digitDecimalStats,
				});
				const rawDigitsDecimalValue = await connection.getVariable(
					"rawDigitsDecimalValue",
				);
				deviceVariableChanged({
					name: "rawDigitsDecimalValue",
					value: rawDigitsDecimalValue,
				});
				const rawDigitsIntegerValue = await connection.getVariable(
					"rawDigitsIntegerValue",
				);
				deviceVariableChanged({
					name: "rawDigitsIntegerValue",
					value: rawDigitsIntegerValue,
				});
				const rawPointerValue = await connection.getVariable("rawPointerValue");
				deviceVariableChanged({
					name: "rawPointerValue",
					value: rawPointerValue,
				});
				const digitsIntegerValue =
					await connection.getVariable("digitsIntegerValue");
				deviceVariableChanged({
					name: "digitsIntegerValue",
					value: digitsIntegerValue,
				});
				const digitsDecimalValue =
					await connection.getVariable("digitsDecimalValue");
				deviceVariableChanged({
					name: "digitsDecimalValue",
					value: digitsDecimalValue,
				});
				const pointerValue = await connection.getVariable("pointerValue");
				deviceVariableChanged({ name: "pointerValue", value: pointerValue });
				const consumption = await connection.getVariable("consumption");
				deviceVariableChanged({ name: "consumption", value: consumption });
				const lastSentTimeStamps =
					await connection.getVariable("lastSentTimeStamps");
				deviceVariableChanged({
					name: "lastSentTimeStamps",
					value: lastSentTimeStamps,
				});
				const digitsFail = await connection.getVariable("digitsFail");
				deviceVariableChanged({ name: "digitsFail", value: digitsFail });
				const rssi = await connection.getVariable("rssi");
				deviceVariableChanged({ name: "rssi", value: rssi });
				console.log("variables subscribed.");
			}
		}
		subscibe();
		return function cleanUp() {
			connection.removeListener("variable", deviceVariableChanged);
		};
	}, [connection]);

	const deviceVariableChanged = (item) => {
		console.log("deviceVariableChanged:", item);
		if (item.name === "lastSentTimeStamps") {
			if (item.value) {
				const val = JSON.parse(item.value);
				val.sendData = new Date(val.sendData).toLocaleString();
				val.sendPicture = new Date(val.sendPicture).toLocaleString();
				val.sendRssi = new Date(val.sendRssi).toLocaleString();
				item.value = val;
			} else {
				const val = {
					sendData: "unknown",
					sendPicture: "unknown",
					sendRssi: "unknown",
				};
				item.value = val;
			}
		} else if (item.name === "digitIntegerStats") {
			if (item.value) {
				item.value.errors =
					"2=grow too much, 3=detection failed, 4=conf error, 5=went backwards";
			}
		} else if (item.name === "digitDecimalStats") {
			if (item.value) {
				item.value.errors =
					"2=grow too much, 3=detection failed, 4=decimal int grow > int val";
			}
		}
		const hit = variables.findIndex((e) => e.name === item.name);
		if (hit !== -1) {
			variables.splice(hit, 1, item);
		} else {
			variables.push(item);
		}
		const arr = [];
		setVariables(arr.concat(variables));
	};

	return (
		<Grid
			container
			direction="row"
			sx={{
				gridAutoColumns: "1fr",
				gap: 1,
				display: "grid",
			}}
		>
			<Grid item container direction="row" gridRow={1} justifyContent="center">
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Variable</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{variables.map((item) => (
								<TableRow key={item.name}>
									<TableCell>{item.name}</TableCell>
									<TableCell>
										{typeof item.value === "object" ? (
											<TextField
												multiline
												fullWidth
												value={JSON.stringify(item.value, undefined, 4)}
											/>
										) : (
											item.value
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
}
