import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Paper,
	TableContainer,
	Typography,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";

export default function AlarmsDialog({ open, infoMsg, title, onClose }) {
	const theme = useTheme();
	const [msg, setMsg] = useState("");

	useEffect(() => {
		console.log("msg type:", typeof infoMsg);
		if (typeof infoMsg === "object") {
			setMsg(JSON.stringify(infoMsg, undefined, 4));
		}
	}, [infoMsg]);

	return (
		<Dialog
			open={open}
			keepMounted
			fullWidth={true}
			maxWidth="lg"
			onClose={onClose}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle>{title}</DialogTitle>

			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					<TableContainer component={Paper} sx={{ marginTop: 1 }}>
						<TextField fullWidth multiline disabled value={msg} />
					</TableContainer>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}
