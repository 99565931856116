import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Paper,
	TableContainer,
	Divider,
	Link,
	Table,
	TableCell,
	TableHead,
	TableBody,
	TableRow,
	IconButton,
	CircularProgress,
	Alert,
	Box,
	Input,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import TransferProgressBar from "./TransferProgressBar";

import { useEffect, useState } from "react";

export default function DeviceDetailsFileTransfer({
	open,
	onClose,
	deviceApi,
	deviceId,
}) {
	const [fileList, setFileList] = useState([]);
	const [fileListError, setFileListError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [downloading, setDownLoading] = useState(false);
	const [downloadData, setDownloadData] = useState({
		downloaded: 0,
		transferRate: 0,
		progress: 0,
	});
	const [uploading, setUploading] = useState(false);
	const [uploadData, setUploadData] = useState({
		uploaded: 0,
		transferRate: 0,
		progress: 0,
	});

	useEffect(() => {
		if (open) {
			//only load file list if dialog is opening
			setLoading(true);
			getFileList();
		}
	}, [open]);

	const getFileList = async () => {
		try {
			if (deviceApi) {
				console.log("requesting file list from device...");
				const resp = await deviceApi.sendCustom("file-list");
				//console.log("Device file list received:", resp);
				setFileList(resp);
				setLoading(false);
				setFileListError(null);
			}
		} catch (err) {
			console.error("Failed to get file list from device:", err);
			setFileListError(err);
			setLoading(false);
		}
	};

	const deleteFile = async (file) => {
		try {
			console.log("Requesting file deletion:", file);
			await deviceApi.sendCustom("removeFile", {
				name: file.name,
			});
			await getFileList();
		} catch (err) {
			console.error("Error deleting file:", err);
		}
	};

	const downloadFile = async (file) => {
		try {
			console.log("Requesting file:", file.name);
			setDownLoading(true);
			let downloaded = 0;
			let transferRate = 0;
			let progress = 0;
			const start = new Date();
			setDownloadData({ downloaded, transferRate, progress });
			const stream = await deviceApi.requestStream("getFile", {
				name: file.name,
			});
			const arr = [];
			stream.on("data", (chunk) => {
				arr.push(chunk);
				console.log("data chunk received");
				downloaded += chunk.length;
				transferRate = `${getReadableFileSizeString((1000 * downloaded) / (new Date() - start))}/s`;
				progress = Math.floor((downloaded / file.size) * 100);
				setDownloadData({ downloaded, transferRate, progress });
			});
			stream.on("end", () => {
				console.log("stream ended");
				const blob = new Blob(arr, {
					type: "application/octet-stream;charset=utf-8;",
				});
				saveToDisk(blob, file.name);
				setDownLoading(false);
			});
		} catch (err) {
			console.error("Failed to get stream:", err);
			setDownLoading(false);
		}
	};

	const saveToDisk = (blob, fileName) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onload = (ev) => {
			const save = document.createElement("a");
			save.href = ev.target.result;
			save.target = "_blank";
			save.download = fileName || "unknown";
			const event = new Event("click", { bubbles: true, cancelable: false }); //document.createEvent('Event');
			//event.initEvent('click', true, true);
			save.dispatchEvent(event);
			setTimeout(() => {
				(window.URL || window.webkitURL).revokeObjectURL(save.href);
			}, 250);
		};
	};

	const getReadableFileSizeString = (fileSizeInBytes) => {
		let i = -1;
		let size = fileSizeInBytes;
		const byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
		do {
			size = size / 1024;
			i++;
		} while (size > 1024);
		return Math.max(size, 0.1).toFixed(1) + byteUnits[i];
	};

	const handleFileUpload = async (e) => {
		try {
			console.log("Handle file upload:", e.target.files[0]);
			let uploaded = 0;
			let transferRate = 0;
			let progress = 0;
			const start = new Date();
			setUploadData({ uploaded, transferRate, progress });
			setUploading(true);

			const file = e.target.files[0];
			if (file) {
				const { stream, ss } = await deviceApi.openWriteStream("sendFile", {
					name: file.name,
				});
				const blobStream = ss.createBlobReadStream(file);
				blobStream.on("data", (chunk) => {
					console.log("on data:", uploaded, file.size);
					uploaded += chunk.length;
					progress = Math.floor((uploaded / file.size) * 100);
					transferRate = `${getReadableFileSizeString((1000 * uploaded) / (new Date() - start))}/s`;
					setUploadData({ uploaded, transferRate, progress });
				});
				blobStream.on("end", () => {
					getFileList();
					setUploading(false);
					e.target.value = null;
				});
				blobStream.pipe(stream);
			}
		} catch (err) {
			console.log("Error uploading file:", err);
			setUploading(false);
		}
	};

	return (
		<Dialog
			open={open}
			keepMounted
			fullWidth={true}
			maxWidth="lg"
			onClose={onClose}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle>File Transfer</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					<Divider textAlign="left" sx={{ paddingBottom: 3 }}>
						File List
					</Divider>
					<TableContainer component={Paper} sx={{ marginTop: 1 }}>
						<Table
							stickyHeader
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Size</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{loading ? (
									<TableRow>
										<TableCell colSpan={3} align="center">
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : fileListError ? (
									<TableRow>
										<TableCell colSpan={3}>
											<Alert severity="error">Error loading file list</Alert>
										</TableCell>
									</TableRow>
								) : (
									fileList.map((file) => (
										<TableRow key={file.name} hover={true}>
											<TableCell>
												<Link
													component="button"
													onClick={() => downloadFile(file)}
												>
													{file.name}
												</Link>
											</TableCell>
											<TableCell>
												{getReadableFileSizeString(file.size)}
											</TableCell>
											<TableCell align="right">
												<IconButton onClick={() => deleteFile(file)}>
													<DeleteIcon color="error" />
												</IconButton>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
					{downloading && (
						<TransferProgressBar
							progress={downloadData.progress}
							transfered={getReadableFileSizeString(downloadData.downloaded)}
							transferRate={downloadData.transferRate}
						/>
					)}
					<Divider textAlign="left" sx={{ paddingBottom: 3, paddingTop: 3 }}>
						Upload File
					</Divider>
					<Box>
						<Input type="file" onChange={handleFileUpload} />
						{uploading && (
							<TransferProgressBar
								progress={uploadData.progress}
								transfered={getReadableFileSizeString(uploadData.uploaded)}
								transferRate={uploadData.transferRate}
							/>
						)}
					</Box>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}
