module.exports = (emitter, event) => {
  let listener = null;

  const promise = new Promise((resolve, reject) => {
    listener = (...ars) => {
      let args = null;

      if (ars.length === 1) {
        args = ars[0];
      } else {
        args = [];

        for (let i = 0; i < ars.length; i++)
          args.push(ars[i]);
        }

        resolve(args);
      };

    emitter.once(event, listener);
  });

  promise.cancel = () => {
    emitter.removeListener(event, listener);
  };

  return promise;
};